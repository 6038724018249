import React, { useCallback, useEffect, useState } from "react";
import { Drawer, message, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ShowConfirmClose from "../Modals/ShowConfirmClose";
import SpecialCardForm from "./SpecialCardsForm";
import {
  getCards,
  getCardById,
  saveSpecialCard,
  updateCard,
} from "../../redux/cardSlice";
import { useTranslation } from "react-i18next";
import { getCardTypes} from "../../redux/cardTypeSlice"; // Import card types

const SpecialCardsDrawer = ({ specialCardId, specialCardHolderId, onClose , reload }) => {
  const { t } = useTranslation();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const dispatch = useDispatch();
  const [makingApiCall, setMakingApiCall] = useState(false);

  const [card, setCard] = useState(undefined);
  const { specialCard } = useSelector((state) => state.cards);

  useEffect(() => {
    const abortController = new AbortController();

    if (specialCardId) {
      setMakingApiCall(true);
      dispatch(getCardById(specialCardId))
        .unwrap()
        .then((data) => {
          setCard(data);
          setMakingApiCall(false);
        })
        .catch(() => {
          setMakingApiCall(false);
        });
    }
    return () => abortController.abort();
  }, [specialCardId, dispatch]);

  const fetchCardTypes = () => {
    dispatch(getCardTypes())
      .unwrap()
      .then(() => setMakingApiCall(false))
      .catch((err) => {
        console.error(err);
        setMakingApiCall(false);
      });
  };

  useEffect(() => {
    fetchCardTypes();
  }, [dispatch]);

  const handleDirty = useCallback(() => {
    setUnsavedChanges(true);
  }, []);

  const onSave = async (values) => {
    const action = specialCardId
      ? updateCard({
          id: specialCardId,
          cardNumber: values.cardNumber,
          cardValidFrom: (() => {
            const date = new Date(values.cardValidRange[0]);
            date.setHours(0, 0, 0, 0);
            return date.toISOString();
          })(),
          cardValidTo: (() => {
            const date = new Date(values.cardValidRange[1]);
            date.setHours(23, 59, 59, 999);
            return date.toISOString();
          })(),
          cardType: values.cardType,
          cardHolder: {
            id: specialCardHolderId,
            name: values.name,
            surname: values.surname,
            identityCardNumber: values.idNumber,
            email: values.email,
            dateOfBirth: (() => {
              const date = new Date(values.dateOfBirth);
              date.setHours(0, 0, 0, 0);
              return date.toISOString();
            })(),
            phoneCountryCode: values.phoneCountryCode,
            phone: values.phone,
          },
        })
      : saveSpecialCard({
          card: {
            cardNumber: values.cardNumber,
            cardValidFrom: (() => {
              const date = new Date(values.cardValidRange[0]);
              date.setHours(0, 0, 0, 0);
              return date.toISOString();
            })(),
            cardValidTo: (() => {
              const date = new Date(values.cardValidRange[1]);
              date.setHours(23, 59, 59, 999);
              return date.toISOString();
            })(),
            cardTypeId: values.cardType,
          },
          cardHolder: {
            name: values.name,
            surname: values.surname,
            dateOfBirth: (() => {
              const date = new Date(values.dateOfBirth);
              date.setHours(0, 0, 0, 0);
              return date.toISOString();
            })(),
            identityCardNumber: values.idNumber,
            email: values.email,
            phoneCountryCode: values.phoneCountryCode,
            phone: values.phone,
          },
        });
    setMakingApiCall(true);

    dispatch(action)
      .then(() => {
        message.success(
          t("specialCardScreen.messages.specialCardSavedSuccessfully")
        );
        onClose();
        reload();
        setUnsavedChanges(false);
      })
      .catch((e) => {
        message.error(e.message);
      })
      .finally(() => {
        setMakingApiCall(false);
      });
  };

  const onSaveFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    message.error(t("specialCardScreen.messages.failedToSaveSpecialCard"));
  };

  const handleClose = useCallback(
    (hasUnsavedChanges) => {
      if (hasUnsavedChanges && !confirmationOpen) {
        setConfirmationOpen(true);
        ShowConfirmClose(() => {
          setConfirmationOpen(false);
          onClose();
          reload();
        });
      } else {
        onClose();
        reload();
      }
    },
    [onClose, reload, confirmationOpen]
  );

  useEffect(() => {
    dispatch(getCards());
  }, [dispatch]);
  return (
    <Drawer
      title={
        !specialCardId
          ? t("specialCardScreen.form.addSpecialCard")
          : t("specialCardScreen.form.editSpecialCard")
      }
      placement="right"
      onClose={() => handleClose(unsavedChanges)}
      width={500}
      open
    >
      <Spin
        size="large"
        spinning={makingApiCall}
        style={{ marginTop: 64 }}
        delay={500}
      >
        {!makingApiCall && (
          <SpecialCardForm
            specialCard={card}
            onSave={onSave}
            onSaveFailed={onSaveFailed}
            onCancel={onClose}
            onDirty={handleDirty}
            unsavedChanges={unsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
            // cardTypes={cardTypes}
          />
        )}
      </Spin>
    </Drawer>
  );
};

export default SpecialCardsDrawer;
